import React, {useState, useCallback, Fragment} from 'react'
import { 
    Text,
    LegacyCard,
    Icon,
    LegacyStack,
    Button,
} from "@shopify/polaris"
import { DeleteIcon, ChevronUpIcon, ChevronDownIcon } from "@shopify/polaris-icons";

import ProductEdit from './productEdit';
import update from 'immutability-helper'
import styled from 'styled-components'

const DeleteWrapper = styled.div`
    cursor: pointer;
`
const EditWrapper = styled.div`
    cursor: pointer;
    color: #008060;
    text-decoration: underline;
`

function CartProductsSetting(props) {
    const { host, shop, token, state, setState, productPickerShow, error, productEdit, setProductEdit, currency, currencySymbol = '$' } = props
    const [productPickerVisible, setProductPickerVisible] = useState(false)
    const [active, setActive] = useState(false);
    const [selectedImage, setSelectedImage] = useState([]);

    const addAnotherProduct = () => {
      if (state.product && !Array.isArray(state.product)) {
        const productItem = {
          product: state.product,
          variants: state.variants ? state.variants :(state.variant ? [state.variant]:[]),
          title: state.title,
          subtitle: state.subtitle,
          message: state.message,
          compareAtPrice: state.compareAtPrice && state.compareAtPrice?.sym ? state.compareAtPrice : {sym:currencySymbol, value:state.compareAtPrice}
        }
        setState(update(state, {
          product: { $set: [productItem, {}] },
        }))
        setProductEdit(1)
      } else {
        let productSize = 1;
        if (state.product === null) {
          setState(update(state,{product:{$set: [{}]}}))
        } else {
          productSize = state.product.length
          setState(update(state,{product:{$push: [{}]}}))
        }
        setProductEdit(productSize)
      }
    }

    const deleteOneProduct = (index) => {
      if (state?.productsPerRow && state?.product && state.product.length && state.productsPerRow > (state.product.length - 1)) {
        setState(update(state, { product: { $splice: [[index, 1]]}, productsPerRow: {$set: (state.product.length - 1)} }))
      } else {
        setState(update(state, { product: { $splice: [[index, 1]]}}))
      }
    }

    const moveItem = (from, to) => {
      const data = state.product
      const f = data.splice(from, 1)[0]
      data.splice(to, 0, f)
      setState(update(state, { product: { $set: [...data] }}))
    }

    const moveUp = (index) => {
      moveItem(index, index-1)
    }

    const moveDown = (index) => {
      moveItem(index, index+1)
    }

    const renderProducts = useCallback((productItem, index = null) => {
      const {variant, variants, product, title = '', description = '', featuredImage} = productItem;
      const productImage =
        !product
          ? 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/200.png?alt=media&token=2cf839eb-05e5-49b1-a591-4fc02e78e94b'
          : product?.image
            ? product?.image
            : product?.images
            ? product?.images[0]?.originalSrc || 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/200.png?alt=media&token=2cf839eb-05e5-49b1-a591-4fc02e78e94b'
            : 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/200.png?alt=media&token=2cf839eb-05e5-49b1-a591-4fc02e78e94b';

      return (
        (index === null || (index !== null && productEdit === index)) ?
          <ProductEdit shop={shop} host={host} token={token} state={state} setState={setState} productPickerShow={productPickerShow} error={error} currency={currency} currencySymbol={currencySymbol} index={index} variant={variant} variants={variants} product={product} productItem={productItem} title={title} description={description} featuredImage={featuredImage} setProductEdit={setProductEdit}/>
          :
          <LegacyCard sectioned key={index ? index + 1 : 0}>
            <LegacyStack alignment="center">
              <LegacyStack.Item fill>
                <LegacyStack alignment="center">
                  <img className="upsellplus-image" src={productImage} style={{width: '20px', height: '20px'}} />
                  <Text as="h3" variant="headingSm">product {index+1} : {product ? product.title: "please choose product"}</Text>
                </LegacyStack>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <LegacyStack alignment="center">
                  <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'center'}}>
                    <div style={{border: '1px solid #C9CCCF', borderRadius: '5px 0 0 5px', borderRight: 'none', padding: '3px 1px', cursor: 'pointer'}} onClick={() => { if(index > 0) {moveUp(index)} }}>
                      <Icon
                        source={ChevronUpIcon}
                        tone={index > 0 ? "base": "subdued"} />
                    </div>
                    <div style={{border: '1px solid #C9CCCF', borderRadius: '0 5px 5px 0', padding: '3px 1px', cursor: 'pointer'}} onClick={() => { if(index < state.product.length-1){ moveDown(index) } }}>
                      <Icon
                        source={ChevronDownIcon}
                        tone={index < state.product.length-1 ? "base": "subdued" } />
                    </div>
                  </div>
                  <EditWrapper onClick={()=>{setProductEdit(index)}}>
                    <a>EDIT</a>
                  </EditWrapper>
                  <DeleteWrapper onClick={()=>deleteOneProduct(index)}>
                    <Button icon={DeleteIcon} tone="critical"></Button>
                  </DeleteWrapper>
                </LegacyStack>
              </LegacyStack.Item>
            </LegacyStack>
          </LegacyCard>
      )
    }, [state, setState, active, productPickerVisible, selectedImage, productPickerShow, productEdit]);

    return (
      <div style={{marginTop: '10px'}}>
        {
          !Array.isArray(state.product)
            ? renderProducts(state)
            : state.product.map((productItem, index) => renderProducts(productItem, index))
        }

        <div style={{marginTop: '20px'}}>
          <Button onClick={addAnotherProduct}  variant="primary">+ Add another product</Button>
        </div>
      </div>
    );
}

export default CartProductsSetting;